import React from "react";

const About = () => {
    return(

        <div
            name="about"
            className="w-full h-full bg-gradient-to-b from-gray-800 to-black text-white"
        >
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">About</p>
                    <p className="text-xl mt-20">
                        I'm Khubayb, a graduate software engineer with a strong drive for crafting reliable, clean code. My journey spans diverse industries, constantly pushing me to refine my coding skills and embrace varied methodologies. Proficient in Java, C#, and C++, I prioritize creating code that's both functional and maintainable.</p>
                    <br/>
                    <p className="text-xl">
                        My web development journey includes building a university auction site and a user access tracker, showcasing my proficiency in PHP, MySQL, HTML, and CSS. Currently, I'm immersed in developing a MERN stack-based social media app for my final project. With hands-on experience in Microsoft Azure and a stint at Egress Software Technologies, where I contributed to enhancing IT infrastructure and handling sensitive data, I've cultivated a versatile skill set. My commitment to growth, paired with customer-centric roles at Direct Line Group and McDonald's, has shaped me into a dedicated software engineer, passionate about delivering quality solutions.</p>            </div>

            </div>
                
        </div>
        
    )
}            

export default About;